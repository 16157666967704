import React from "react"

import Herobgimage from "../images/herobg.jpeg"

import { Seo, Layout, Content_section, HeroBanner } from "components"

const Herodata = {
  bgimage: Herobgimage,

  description: "Alpha modular Packages ",
}
const content = [
  {
    rignt_title: "Premium ",

    list: [
      {
        list: "Carcass and shutters will be in-house neem core ply wood , wet areas will be using Boiling water proof ply ",
      },
      { list: "Colour Laminates ( BELL, VIRGO, STAR, )" },
      {
        list: "Carcass laminate will be half white 0.72",
      },
      {
        list: "Edge banding (shutters 1.5mm thickness) carcass 0.8mm thickness)",
      },
      { list: "Hardware ebco hinges and channels " },
      {
        list: "Except loft shutters everything soft close",
      },
      { list: "With 100% steel basket" },
    ],
  },
  {
    rignt_title: "Premium plus ",

    list: [
      {
        list: "Carcass and shutters will be in-house neem core ply wood , wet areas will be using Boiling water proof ply ",
      },
      {
        list: "Colour Laminates ( Green lam, Merino, Kajaria, ALTIS + multiple brands choice in the same segments)",
      },
      {
        list: "Carcass laminate will be fabric 0.72",
      },
      {
        list: "Edge banding (shutters 2mm thickness) carcass 0.8mm thickness)",
      },
      {
        list: "Hardware ( Ebco hinges and channels)",
      },
      {
        list: "Except loft shutters everything soft close",
      },
      {
        list: "Ebco Tandem 3 set unit and steel Bottle pull out ",
      },
      {
        list: "Concealed /GOLA handles for kitchen and slider wardrobes ",
      },
    ],
  },
  {
    rignt_title: "Luxury ",

    list: [
      {
        list: "Free kitchen 3D model before execution ",
      },
      {
        list: "Carcass will be in-house neem core plywood with wet areas using boiling water proof plywood ",
      },
      {
        list: "Shutters will be of in house HDHMR ",
      },
      {
        list: "Colour Laminates ( Green lam, Merino, Kajaria, ALTIS + multiple brands choice in the same segments) ",
      },
      {
        list: "Carcass laminate will be fabric 0.72 ",
      },
      {
        list: "Edge banding Pegasus brand (shutters 2mm thickness) carcass 0.8mm thickness) ",
      },
      {
        list: "Hardware used will be HETTICH channels and hinges ",
      },
      {
        list: " Except loft shutters everything will be soft close",
      },
      {
        list: " HETTICH Tandem 3 set unit and Tandem Bottle pull out ",
      },
      {
        list: "Concealed /GOLA profile handles for kitchen and slider wardrobes  ",
      },
      {
        list: "Profile saint gobain glass shutters (hydraulic/hinge type)4sqft ",
      },
    ],
  },
  {
    rignt_title: "Luxury Plus ",

    list: [
      {
        list: "Free 3D model personal customisation for kitchen and living /dining room",
      },
      {
        list: "Carcass and shutters everything will be HDHMR",
      },
      {
        list: "All shutter panels will be Korean import 6H acrylic (Matt/gloss)",
      },
      {
        list: "Carcass will have coloured 0.8laminate",
      },
      {
        list: "Edge banding Rehau brand (shutters 2mm thickness) carcass 0.8mm thickness)",
      },
      {
        list: "Hardware used will be only HETTICH",
      },
      {
        list: "including loft everything will be soft close",
      },
      {
        list: "HETTICH Tandem 3 set unit and Tandem Bottle pull out ",
      },
      {
        list: "Concealed /GOLA handles for kitchen and slider wardrobes tray accessories ",
      },
      {
        list: "Profile saint gobain glass shutters (hydraulic/hinge type)8sqft",
      },
      {
        list: "Led Profiling work for kitchen and crockery",
      },
      {
        list: "Wardrobe auto sensor LED work",
      },
    ],
  },
]

const Package = ({ data, location }) => {
  return (
    <Layout>
      <Seo title="Home" location={location?.pathname} />
      <HeroBanner data={Herodata} />

      <Content_section data={content} Class="package-content" />
    </Layout>
  )
}

export default Package
